.hidden {
  display: none !important;
}

.is-hidden {
  display: none;
}

.translation_missing {
  background: #f3f315;
  color: #000;
}

//CONTAINER
.container-fluid {
  .app-wrapper & {
    padding-right: 2rem;
  }
}

//BORDERS
.border-top-left-0 {
  border-top-left-radius: 0 !important;
}

//LISTS
ul:not(.reset-list-style) {
  padding: 0;
  list-style: none;
}

//COLORS
.red {
  color: var(--danger);
}

//FLEX
.flex {
  display: flex;
}

//POSITION
.left-0 {
  left: 0;
}

//TEXT
strong,
.bold {
  font-family: var(--font-family-sans-serif-bold);
}

.color-light {
  color: var(--gray)
}

small,
.small {
  font-size: 14px;
  line-height: 21px;
}

//TITLES
.title {
  &--withdrawals-to {
    font-size: 1.4rem;
    font-weight: bold;
  }
}

//LINKS
.link {
  &--semibold {
    font-family: var(--font-family-sans-serif-semibold);
  }
}

//CURSOR
.cursor-default {
  cursor: default;
}

/* Hide Re-captcha iFrame */
iframe[src="about:blank"] {
  display: none;
}

.field_with_errors {
  display: inline;
}

.no-wrap {
  white-space: nowrap;
}

.app-wrapper {
  height: 100%;
  .reset-margin {
    margin: 0;
  }
}

#sidekiq-web {
  height: 85vh;
}

//SPACE
.mw-120 {
  min-width: 12rem;
}

//OVERLAYS
%overlay_close {
  transition: transform .2s;
  outline: none;
  opacity: 1;
  padding: 0;
  margin: -.8rem 0 0;
  width: 1.7rem;
  height: 1.7rem;
  right: 2.4rem;
  top: 3rem;

  &:before,
  &:after {
    color: inherit;
    background: #7b878d;
  }

  &:hover,
  &:focus {
    transform: rotate(90deg);

    &:before,
    &:after {
      color: inherit;
      background: #7b878d;
    }
  }

  span {
    display: none;
  }
}

//ICONS
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.icon--add {
  vertical-align: bottom;
  margin-right: 1.2rem;
  font-size: 2rem;
}

.icon--outer,
.utilities-icon {
  background: inherit;
  .icon--inner,
  &.fa-sync,
  &.fa-redo {
    position: absolute;
    margin-left: -4px;
    padding: 2px;
    border-radius: 100%;
    font-size: 10px;
    background: inherit;
    margin-top: -1px;
  }

  &.fa-line-columns {
    vertical-align: text-top;
  }

  .fa-times {
    margin-left: -5px;
    padding: 2px;
    margin-top: -5px;
    color: var(--danger);
  }

  .fa-redo {
    margin-left: -6px;
    padding: 1px;
    font-size: 8px;
    margin-top: 4px;
  }

  .fa-pencil {
    background: none;
    margin-left: -5px;
    text-shadow: -2px -1px 1px #fff;
  }
}

.clear-both {
  clear: both;
}

.clear-both {
  clear: both;
}

//HEADERS
%header-semi-bold {
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 2.3rem 0 -.8rem;
  font-family: var(--font-family-sans-serif-bold);
}
